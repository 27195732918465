table, .table{
  padding-bottom: 0;
}

.button-comment{
  width: 0;
  padding: 10px 0 !important;
}

.height0{
  height: 0;
}

.field{
  .field-text{
    width: 25%;
  }
  .field-input-width{
    width: 75%;
    margin-left: 25%;
  }
}


@media screen and (min-width: 768px) {
  .hidden-pc {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .hidden-sp {
    display: none !important;
  }
  .tabs-destination .table{
    .table-head {
      display: block !important;
    }
    .table-row .gutter-s:before{
      content: none !important;
    }
  }
  .table-row .gutter-s {
    p {
      color: #76B243;
      font-weight: bold;
      margin-bottom: 0;
      margin-top: 0;
    }
    .chosen-single {
      margin: 5px 0;
    }
  }
  .field{
    .field-label{
      float: inherit;
      display: block;
      width: 100%;
    }
    .field-input{
      float: inherit;
      display: block;
      margin: 0;
      width: 100%;
      margin-top: 5px;
    }
  }

  .col-organization{
    width: 85% !important;
  }

  .horizontal-divider{
    display: none;
  }
}

@media only screen and (max-width: 480px) {

  .height0{
    height: auto;
  }

  /*============Title Bar============*/
  .table-content .table-row .col:empty {
    display: none;
  }
  #titlebar{
    padding: 5px 10px;
    height: 115px;
    a{
      padding: 10px 5px;
    }
    .titlebar-left{
      float: inherit;
    }
    .titlebar-title{
      padding: 10px 0;
      float: inherit;
    }
    .titlebar-right{
      margin: 0 auto;
      float: inherit;
      text-align: center;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
    }
    .titlebar-item{
      float: inherit;
      display: inline-block;
    }
  }

  .gutter-s.remove-button{
    text-align: center;
  }
  .with-header{
    top: 115px !important;
  }
  /*============Banner============*/
  #banner{
    height: auto;
    position: static;
  }
  .banner-text{
    margin-bottom: 10px;
  }

  /*============ToolBar============*/
  .toolbar .toolbar-left{
    display: block;
  }

  .toolbar-left .toolbar-item-s{
    margin: 0;
  }

  .toolbar .toolbar-item{
    display: block;
    float: inherit;
    margin: 3px;
  }
  .toolbar.with-banner{
    height: auto;
  }

  .toolbar .toolbar-left .toolbar-item >.toolbar-item{
    display: none;
  }

  .toolbar .toolbar-left, .toolbar .toolbar-right{
    margin: 0;
  }

  .toolbar-left .toolbar-item-s{
    float: none;
    margin: 5px 0;
  }


  body .ui-tooltip{
    display: none !important;
  }

  /*============Chosen============*/
  .chosen-container .chosen-results li{
    height: auto;
  }

  /*============Select============*/
  select {
    display: block;
    font-size: 16px;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
  }
  select::-ms-expand {
    display: none;
  }
  select:hover {
    border-color: #888;
  }
  select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }
  select option {
    font-weight:normal;
  }

  /*============Table============*/
  .table-content .table-row .col{
    padding: 3px 0 !important;
  }

  .token-status{
    height: auto;
    padding: 0 10px;
    line-height: 30px;
    min-width: 80px;
  }

  table tbody.with-head td:before, .table .table-content.with-head .col:before{
    min-width: 105px;
  }

  .text-align-center, .text-align-c, .text-align-right, .text-align-r{
    text-align: left !important;
  }

  .icon-center{
    text-align: center;
  }

  .table-content .table-row .col.reflection{
    width: 100% !important;
    height: auto;
  }

  span.reflection-status{
    display: inline-block;
    margin: 0;
    min-width: 80px;
    height: auto;
    line-height: 30px;
    padding: 0;
  }

  .field-mobile-max-width{
    width: 100%;
  }
}