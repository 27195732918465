body.login-page{
  font-family: -apple-system,BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #000;
  background-color: #E8E8E8;
}

.chosen-container .chosen-results li{
  min-height: 38px;
  height: initial;
}

.page-login-register{
  background-color: #E8E8E8;
  min-height: 100vh;
  #page-logo{
    max-width: 770px;
    width: 770px;
    img{
      height: initial;
      width: 200px;
    }
  }
  #page-main{
    background: #F7F7F7;
    border: 1px solid #D2D2CF;
    border-radius: 5px;
    max-width: 770px;
    width: 770px;
  }
  &.page-login{
    #page-logo{
      width: 470px;
      max-width: 470px;
    }
    #page-main{
      width: 470px;
      max-width: 470px;
      .field{
        margin: 1.5rem 0 1rem;
      }
      .content-inset{
        .signup-google{
          margin: 0;
          margin-bottom: 1rem;
          a{
            width: 100%;
          }
        }
        .register-button{
          color: #79B320;
          font-size: 1rem;
          margin: 0;
          &:hover{
            text-decoration: underline;
          }
        }
        .button-default{
          width: 100%;
          height: 50px;
          font-size: 1rem;
          margin: 2rem 0 1rem;
        }
        hr{
          margin: 0.5rem 0 1rem;
        }
      }
    }
  }
  .content-register, .content-inset{
    padding: 2em;
    font-size: 1rem;
    h1{
      font-weight: bold;
      font-size: 2.5rem;
      margin-top: 0;
    }
    hr{
      margin: 1rem 0;
      border-top: 1px solid rgba(0,0,0,.1);
    }
    h1{
      color: #B6B6B6;
    }
    .not-register{
      text-align: right;
      .float-right{
        float: initial !important;
      }
    }
    .another-login {
      color: #9b9b9b;
      margin: 2rem 0;
      text-align: center;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        height: 0.5px;
        width: 46%;
        background: rgba(0,0,0,.1);
        top: 50%;
        left: 0;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
      }
      &:after{
        content: '';
        position: absolute;
        height: 0.5px;
        width: 46%;
        background: rgba(0,0,0,.1);
        top: 50%;
        right: 0;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
      }
    }
    .signup-google{
      text-align: center;
      margin: 2rem 0 3rem;
      font-size: 1rem;
      a{
        background-color: #4285f4;
        height: 50px;
        line-height: 50px;
        color: #ffffff;
        width: 48%;
        padding: 0;
        font-weight: bold;
        border-radius: 1px;
        border: 1px solid transparent;
        display: inline-block;
        box-sizing: border-box;
        text-align: center;
        div{
          padding: 15px;
          background-color: #fff;
          float: left;
          border-radius: 1px;
          img{
            height: 18px;
            width: 18px;
            display: block;
          }
        }
      }
    }
    .field{
      margin: 1.5rem 0 2rem;
      .field-label{
        float: initial;
        width: initial;
        line-height: initial;
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
      }
      .field-input{
        margin: 0;
        float: initial;
        width: 100%;
      }
      .field-error{
        margin: 0;
        width: 100%;
      }
      .field-error-smaller{
        width: 100%;
      }
    }
    .with-indent{
      margin-left: 0 !important;
      width: initial !important;
    }
    .form-actions{
      margin-left: 0 !important;
      .button-default{
        margin: 0;
        padding: 10px 30px;
        font-size: 1.25em;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .page-login-register{
    padding: 0 15px !important;
    height: inherit !important;
    .content-register {
      padding: 1em;
    }
  }
}