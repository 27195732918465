/* Students */
@media only screen and (max-width: 767px) {
  .toolbar{
    &.volunteer-list{
      .toolbar-left{
        width: 100%;
      }
      .toolbar-item {
        width: 100% !important;
      }
    }
  }
  #student_options{
    padding: 10px 0;
    margin: 0 auto;
  }
  .field{
    .field-input{
      input[type="text"] {
        width: 100%;
      }
      .with-space-b.with-space-a{
        display: block;
        margin: 10px 0;
      }
      .field-input-checkbox{
        float: initial;
        padding: 0;
        border: none;
      }
      &.field-input-group{
        margin-top: 5px !important;
      }
      .button{
        display: block !important;
        margin: 5px 0;
      }
    }
    .field-label{
     &.field-label-right{
       padding: 0;
     }
    }
  }
  .content-inset{
    .grid-input{
      input[type=radio] {
        opacity: 0;
      }
    }
    .field-input-group{
     &.with-grid-input{
       width: 100%;
     }
    }
  }
  .token-other{
    .token-other-input{
      float: initial;
      padding: 0;
      input[name='other'] {
        width: 100%;
      }
    }
  }
  // Table Organizations
  .table{
    .table-content{
     &.with-head{
       .table-row{
         .resources-long-text{
           height: initial;
         }
         .timeslots{
           padding-left: 105px;
           display: block;
         }
         .px-10{
           &:before{
             content: attr(data-label) " ";
             float: left;
             font-style: italic;
             font-weight: bold;
             padding-right: 15px;
             color: #76B243;
             min-width: 100px;
           }
         }
       }
     }
    }
  }
  .checkin-skills-list{
    display: flex;
    flex-wrap: wrap;
    .checkin-skills{
      max-width: 50%;
      flex: 0 0 50%;
      position: relative;
      width: 100%;
    }
  }
  .table-organization{
    display: block;
    .px-10{
      flex: initial;
      max-width: initial;
    }
    .f-full{
      .d-flex{
        display: block;
        .px-10{
          flex: initial;
          max-width: initial;
          span{
            padding-left: 115px;
            display: block;
          }
          &:empty {
            display: none;
          }
        }
      }
    }
  }
}